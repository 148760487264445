import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoutes from "./components/authRoutes";
import LoginSignupRoutes from "./components/loginSignupRoutes";
import NotFound from "./components/notFound";
import ForgotPasswordPage from "./pages/loginSignup/fotgotPassword";
import LoginPage from "./pages/loginSignup/loginScreen";
import OtpScreen from "./pages/loginSignup/otpScreen";
import ResetPasswordPage from "./pages/loginSignup/resetPassword";
import SignUpPage from "./pages/loginSignup/signUpScreen";
import OrderPage from "./pages/orders";
import CreateFulFillableOrderPage from "./pages/orders/createFulFillableOrder";
import CreateRegularOrderPage from "./pages/orders/createRegularOrder";
import EditFulFillableOrderPage from "./pages/orders/editFulFillableOrder";
import EditRegularOrderPage from "./pages/orders/editRegularOrder";
import SaleChannelOrderPage from "./pages/orders/saleChannelOrder";
import UploadOrders from "./pages/orders/uploadOrders";
import Inventory from "./pages/products/inventory";
import Products from "./pages/products/products";
import ShipmentPage from "./pages/shipments";
import { EnumRoutesUrls } from "./utilities/enum";
import {
  handleGetAccessTokenByRefreshToken,
  useHistory,
} from "./utilities/helpers/Helpers";
function App() {
  const navigate = useNavigate();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const { currentPath } = useHistory();
  useEffect(() => {
    let timer;

    if (currentPath !== EnumRoutesUrls.LOG_IN) {
      timer = setInterval(() => {
        handleGetAccessTokenByRefreshToken(navigate, LanguageReducer);
      }, 14 * 60 * 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentPath, handleGetAccessTokenByRefreshToken]);

  return (
    <Box>
      {" "}
      <Routes>
        <Route
          path="/"
          element={
            <LoginSignupRoutes>
              <LoginPage />
            </LoginSignupRoutes>
          }
        />
        <Route
          path="/login"
          element={
            <LoginSignupRoutes>
              <LoginPage />
            </LoginSignupRoutes>
          }
        />
        <Route
          path="/signup"
          element={
            <LoginSignupRoutes>
              <SignUpPage />
            </LoginSignupRoutes>
          }
        />
        <Route
          path="/verify-otp"
          element={
            <LoginSignupRoutes>
              <OtpScreen />
            </LoginSignupRoutes>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <LoginSignupRoutes>
              <ForgotPasswordPage />
            </LoginSignupRoutes>
          }
        />

        <Route
          path="/reset-password"
          element={
            <LoginSignupRoutes>
              <ResetPasswordPage />
            </LoginSignupRoutes>
          }
        />

        <Route
          path="/orders-dashboard/*"
          element={
            <PrivateRoutes>
              <OrderPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/sale-channel-orders/*"
          element={
            <PrivateRoutes>
              <SaleChannelOrderPage />
            </PrivateRoutes>
          }
        />

        <Route
          path="/create-fulfillable-order"
          element={
            <PrivateRoutes>
              <CreateFulFillableOrderPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/create-regular-order"
          element={
            <PrivateRoutes>
              <CreateRegularOrderPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/upload-orders"
          element={
            <PrivateRoutes>
              <UploadOrders />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-order-regular/*"
          element={
            <PrivateRoutes>
              <EditRegularOrderPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-order-fulfillable/*"
          element={
            <PrivateRoutes>
              <EditFulFillableOrderPage />
            </PrivateRoutes>
          }
        />

        <Route
          path="/shipments/*"
          element={
            <PrivateRoutes>
              <ShipmentPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="/products/*"
          element={
            <PrivateRoutes>
              <Products />
            </PrivateRoutes>
          }
        />
        <Route
          path="/inventory/*"
          element={
            <PrivateRoutes>
              <Inventory />
            </PrivateRoutes>
          }
        />

        <Route
          path="*"
          element={
            <PrivateRoutes type="404">
              <NotFound />
            </PrivateRoutes>
          }
        />
      </Routes>
      <ToastContainer />
    </Box>
  );
}

export default App;

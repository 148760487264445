import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styleSheet } from "../../assets/styles/style";
import React from "react";
import { useLanguageReducer } from "../../utilities/helpers/Helpers";

export default function ModalComponent(props) {
  const {
    open,
    onClose,
    title,
    maxWidth = "xl",
    fullScreen = false,
    children,
    loading,
    actionBtn,
    component,
    onSubmit,
    onInvalid,
    height = "auto",
  } = props;

  const LanguageReducer = useLanguageReducer();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          height: height,
          position: "unset !important",
        },
        component: component,
        onSubmit: onSubmit,
        onInvalid: onInvalid,
      }}
    >
      <DialogTitle
        textAlign={"center"}
        sx={{
          padding: {
            md: "16px 24px",
            sm: "8px 24px",
            xs: "3px 24px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "25px", sm: "20px", xs: "16px" },
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: { md: "20px 24px", sm: "20px 20px", xs: " 20px" },
          paddingBottom: { md: "20px", sm: "16px", xs: " 4px" },
        }}
      >
        {children}
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            ...styleSheet.modalDismissButton,
            borderBottomLeftRadius: "20px !important",
          }}
          onClick={onClose}
        >
          {LanguageReducer.DISMISS_TEXT}
        </Button>
        {actionBtn}
      </DialogActions>
    </Dialog>
  );
}

import React, { useState } from "react";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import { purple } from "@mui/material/colors";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import {
  CustomColorLabelledOutline,
  getLowerCase,
  PaymentAmountBox,
  PaymentMethodBox,
  PaymentMethodCheckbox,
  placeholders,
  QucikPaymentAmountBox,
} from "../../../utilities/helpers/Helpers";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { styleSheet } from "../../../assets/styles/style";
import CustomRHFPhoneInput from "../../../.reUseableComponents/TextField/CustomRHFPhoneInput";
import {
  addressSchemaEnum,
  SchemaTextField,
  useGetAddressSchema,
} from "../../../utilities/helpers/addressSchema";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  EnumOptions,
  EnumPaymentMethod,
  EnumPaymentStatus,
  inputTypesEnum,
} from "../../../utilities/enum";
import { CreateOrder } from "../../../api/AxiosInterceptors";
import UtilityClass from "../../../utilities/UtilityClass";
import { successNotification } from "../../../utilities/toast";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";

const QuickOrderPlaceModal = (props) => {
  const {
    open,
    onClose,
    carrierId,
    allCountries,
    addedOrderBoxes,
    storeId,
    stationId,
    saleChannelConfigIds,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    control,
    unregister,
  } = useForm();
  const {
    showMoreInfoBtn,
    selectedAddressSchema,
    addressSchemaSelectData,
    addressSchemaInputData,
    handleSetSchema,
    handleAddRemoveMoreInfoFields,
    handleChangeInputAddressSchema,
    handleChangeSelectAddressSchemaAndGetOptions,
    handleSetSchemaValueForUpdate,
  } = useGetAddressSchema(setValue, true, carrierId?.CarrierId);
  const navigate = useNavigate();
  const schemaFieldsLength = [
    ...addressSchemaSelectData,
    ...addressSchemaInputData,
  ].length;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const handleFocus = (event) => event.target.select();
  const paymentMethodoptions = [
    { id: 1, label: LanguageReducer?.languageType?.ORDERS_PREPAID },
    { id: 2, label: LanguageReducer?.languageType?.ORDERS_CASH_ON_DELIVERY },
  ];
  const [loading, setLoading] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [selectedPMOption, setselectedPMOption] = useState(2);
  const handleCheckboxChange = (optionId) => {
    setselectedPMOption(optionId);
  };
  const createOrder = async (data) => {
    const param1 = prepareCreateOrderData(data);
    console.log(param1);
    setLoading(true);
    CreateOrder(param1)
      .then((res) => {
        // console.log("res:::", res);
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);
        } else {
          successNotification(
            LanguageReducer?.languageType?.PRODUCT_CREATED_SUCCESSFULLY_TOAST
          );
          navigate("/orders-dashboard");
        }
      })
      .catch((e) => {
        console.log("e", e);
        UtilityClass.showErrorNotificationWithDictionary(
          e?.response?.data?.errors
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const prepareCreateOrderData = (data) => {
    const itemValue = subtotal;
    let orderItems = [
      {
        price: 0,
        description:
          Array.isArray(data.descriptionOfPieces) &&
          data.descriptionOfPieces.length > 0
            ? data.descriptionOfPieces[0]
            : "",
        remarks: "",
        quantity: 1,
        discount: 0,
      },
    ];

    const param1 = {
      orderList: [
        {
          storeId: storeId,
          SaleChannelConfigId: saleChannelConfigIds,
          channelId: data.storeChannel?.id,
          orderTypeId: 1,
          orderDate: UtilityClass.getFormatedDateWithoutTime(new Date()),
          description: data.description,
          remarks: data.remarks,
          amount: Number(subtotal),
          cShippingCharges: 0,
          paymentStatusId:
            EnumPaymentMethod.Prepaid == selectedPMOption
              ? EnumPaymentStatus.Paid
              : EnumPaymentStatus.Unpaid,
          weight: data.weight,
          itemValue: itemValue,
          orderRequestVia: 1,
          paymentMethodId: selectedPMOption,
          stationId: stationId.toString(),
          discount: 0,
          vat: 0,
          refNo: data?.refNo,
          orderNote: {
            note: null,
          },
          orderAddress: {
            countryId: selectedAddressSchema.country,
            cityId: selectedAddressSchema.city,
            areaId: selectedAddressSchema.area,
            streetAddress: selectedAddressSchema.streetAddress,
            streetAddress2: selectedAddressSchema.streetAddress2,
            houseNo: selectedAddressSchema.houseNo,
            buildingName: selectedAddressSchema.buildingName,
            landmark: selectedAddressSchema.landmark,
            provinceId: selectedAddressSchema.province,
            pinCodeId: selectedAddressSchema.pinCode,
            stateId: selectedAddressSchema.state,
            zip: selectedAddressSchema.zip,
            addressTypeId: 0,
            latitude: getValues("lat&long")?.split(",")[0] || null,
            longitude: getValues("lat&long")?.split(",")[1] || null,
            orderAddressId: 0,
            customerName: data.customerName,
            email: data.email,
            mobile1: data.mobile1,
            mobile2: data.mobile2,
          },
          orderTaxes: [],
          orderBoxs: addedOrderBoxes.map(({ clientOrderBoxId }) => ({
            clientOrderBoxId,
          })),
          orderItems: orderItems,
        },
      ],
    };
    // console.log("params", param1);

    return param1;
  };
  return (
    <>
      <ModalComponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        title={"Quick Place Order"}
        actionBtn={
          <ModalButtonComponent
            title={"Create Order"}
            bg={purple}
            loading={loading}
            type="submit"
            onClick={handleSubmit(createOrder)}
          />
        }
      >
        <form onSubmit={handleSubmit(createOrder)}>
          <Grid container spacing={1}>
            <Grid
              item
              md={9}
              container
              spacing={2}
              sx={{
                paddingRight: {
                  md: 1,
                  sm: 0,
                  xs: 0,
                },
              }}
            >
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                sx={{
                  pt: {
                    md: "16px",
                    sm: "8px !important",
                    xs: "2px !important",
                  },
                }}
              >
                <InputLabel
                  required
                  sx={{
                    ...styleSheet.inputLabel,
                    mt: {
                      md: "7px !important",
                      sm: "7px !important",
                      xs: "15px !important",
                    },
                    fontSize: {
                      md: "12px !important",
                      sm: "12px !important",
                      xs: "10px !important",
                    },
                    mb: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "0px !important",
                    },
                  }}
                >
                  {LanguageReducer?.languageType?.ORDERS_CUSTOMER_NAME}
                </InputLabel>
                <TextField
                  type="text"
                  placeholder={placeholders.name}
                  onFocus={handleFocus}
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="customerName"
                  {...register("customerName", {
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message:
                        LanguageReducer?.languageType
                          ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                    },
                  })}
                  error={Boolean(errors.customerName)}
                  helperText={errors.customerName?.message}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: { md: "38.5px", sm: "38.5px", xs: "30px" },
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "12px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: {
                        md: "14px !important",
                        sm: "14px !important",
                        xs: "12px !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                sx={{
                  pt: {
                    md: "16px",
                    sm: "8px !important",
                    xs: "2px !important",
                  },
                }}
              >
                <InputLabel
                  required
                  sx={{
                    ...styleSheet.inputLabel,
                    mt: {
                      md: "7px !important",
                      sm: "7px !important",
                      xs: "2px !important",
                    },
                    fontSize: {
                      md: "12px !important",
                      sm: "12px !important",
                      xs: "10px !important",
                    },
                    mb: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "0px !important",
                    },
                  }}
                >
                  {LanguageReducer?.languageType?.ORDERS_PHONE_NO}
                </InputLabel>
                <CustomRHFPhoneInput
                  error={LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT}
                  name="mobile1"
                  control={control}
                  required
                  borderRadius={"3px"}
                />
              </Grid>
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
                sx={{
                  pt: {
                    md: "16px",
                    sm: "8px !important",
                    xs: "2px !important",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    ...styleSheet.inputLabel,
                    mt: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "2px !important",
                    },
                    fontSize: {
                      md: "12px !important",
                      sm: "12px !important",
                      xs: "10px !important",
                    },
                    mb: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "0px !important",
                    },
                  }}
                >
                  {LanguageReducer?.languageType?.ORDERS_REF_NO}
                </InputLabel>
                <TextField
                  type="text"
                  placeholder={placeholders.refNo}
                  onFocus={handleFocus}
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="refNo"
                  name="refNo"
                  {...register("refNo")}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: { md: "38.5px", sm: "38.5px", xs: "30px" },
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "12px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: {
                        md: "14px !important",
                        sm: "14px !important",
                        xs: "12px !important",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={schemaFieldsLength === 0 ? 12 : 4}
                sm={6}
                xs={12}
                sx={{
                  pt: {
                    md: "16px",
                    sm: "8px !important",
                    xs: "2px !important",
                  },
                }}
              >
                <InputLabel
                  required
                  sx={{
                    ...styleSheet.inputLabel,
                    mt: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "2px !important",
                    },
                    fontSize: {
                      md: "12px !important",
                      sm: "12px !important",
                      xs: "10px !important",
                    },
                    mb: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "0px !important",
                    },
                  }}
                >
                  {LanguageReducer?.languageType?.ORDERS_COUNTRY}
                </InputLabel>
                <SelectComponent
                  name="country"
                  control={control}
                  options={allCountries}
                  getOptionLabel={(option) => option?.name}
                  isRHF={true}
                  required={true}
                  optionLabel={EnumOptions.COUNTRY.LABEL}
                  optionValue={EnumOptions.COUNTRY.VALUE}
                  mobileHeight={28}
                  {...register("country", {
                    required: {
                      value: true,
                    },
                  })}
                  value={getValues("country")}
                  onChange={(event, newValue) => {
                    const resolvedId = newValue ? newValue : null;
                    handleSetSchema(
                      "country",
                      resolvedId,
                      setValue,
                      unregister
                    );
                  }}
                  errors={errors}
                />
              </Grid>
              {[...addressSchemaSelectData, ...addressSchemaInputData].map(
                (input, index, arr) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{
                      pt: {
                        md: "16px",
                        sm: "8px !important",
                        xs: "2px !important",
                      },
                    }}
                  >
                    <SchemaTextField
                      loading={input.loading}
                      disabled={input.disabled}
                      isRHF={true}
                      type={input.type}
                      name={input.key}
                      required={input.required}
                      optionLabel={addressSchemaEnum[input.key]?.LABEL}
                      optionValue={addressSchemaEnum[input.key]?.VALUE}
                      register={register}
                      options={input.options}
                      errors={errors}
                      label={input.label}
                      value={getValues(input.key)}
                      fontSizeForQucikModal="10px !important"
                      marginTopForQucikModal="2px !important"
                      marginBottomForQucikModal="0px !important"
                      heightForQucikModal="30px !important"
                      mobileHeight={29}
                      onChange={
                        getLowerCase(input.type) === inputTypesEnum.SELECT
                          ? (name, value) => {
                              handleChangeSelectAddressSchemaAndGetOptions(
                                input.key,
                                index,
                                value,
                                setValue,
                                input.key
                              );
                            }
                          : (e) => {
                              handleChangeInputAddressSchema(
                                input.key,
                                e.target.value,
                                setValue
                              );
                            }
                      }
                    />
                  </Grid>
                )
              )}
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                sx={{
                  pt: {
                    md: "16px",
                    sm: "8px !important",
                    xs: "2px !important",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    ...styleSheet.inputLabel,
                    mt: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "2px !important",
                    },
                    fontSize: {
                      md: "12px !important",
                      sm: "12px !important",
                      xs: "10px !important",
                    },
                    mb: {
                      md: "4px !important",
                      sm: "4px !important",
                      xs: "0px !important",
                    },
                  }}
                >
                  {LanguageReducer?.languageType?.ORDERS_REMARKS}
                </InputLabel>
                <TextField
                  placeholder="Remarks"
                  onFocus={handleFocus}
                  size="small"
                  multiline
                  fullWidth
                  variant="outlined"
                  id="remarks"
                  name="remarks"
                  {...register("remarks", {
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message:
                        LanguageReducer?.languageType
                          ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                    },
                  })}
                  error={Boolean(errors.remarks)}
                  helperText={errors.remarks?.message}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: { md: "38.5px", sm: "38.5px", xs: "30px" },
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "12px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: {
                        md: "14px !important",
                        sm: "14px !important",
                        xs: "12px !important",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              sx={{
                borderLeft: {
                  md: "1px dashed",
                  sm: "none",
                  xs: "none",
                },
                borderTop: {
                  md: "none",
                  sm: "1px dashed",
                  xs: "none",
                },
                mt: {
                  md: 0,
                  sm: 0.5,
                  xs: 0.5,
                },
                pt: {
                  md: "16px",
                  sm: "0px !important",
                  xs: "2px !important",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: { md: "8px", sm: "2px", xs: "0px" },
                }}
              >
                <QucikPaymentAmountBox
                  required
                  value={subtotal}
                  onChange={(e) => {
                    setSubtotal(e.target.value);
                  }}
                  title={LanguageReducer?.languageType?.ORDERS_SUBTOTAL}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "flex-end",
                    height: "25px !important",
                  }}
                >
                  {paymentMethodoptions.map((option) => (
                    <PaymentMethodCheckbox
                      key={option.id}
                      checked={selectedPMOption === option.id}
                      onChange={() => handleCheckboxChange(option.id)}
                      label={option.label}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </>
  );
};

export default QuickOrderPlaceModal;

import { useEffect, useState } from "react";
import { GetSalePersonConfig } from "../../api/AxiosInterceptors";
import { fetchMethod } from "../../utilities/helpers/Helpers";

export const useSalePersonHook = (setValue) => {
  const [store, setStore] = useState("");
  const [storeObj, setStoreObj] = useState("");
  const [salePersonIds, setSalePersonIds] = useState("");
  const [saleChannelConfigIds, setSaleChannelConfigIds] = useState("");
  const [stationId, setStationId] = useState("");

  const [salePersonConfig, setSalePersonConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  // handleGetAllsalePersonConfig
  const handleGetAllsalePersonConfig = async () => {
    setLoading(true);
    const { response } = await fetchMethod(() => GetSalePersonConfig());
    if (response && response.result) {
      setSalePersonConfig(response.result);
      const { saleChannelConfigId, salePersonId, store, stationId } =
        response.result;

      if (setValue) {
        setValue("store", store);
      }
      setStoreObj(store);
      let storeIDs = store.storeId.toString() || "";
      const scci = saleChannelConfigId.toString() || "";
      const spIds = salePersonId.toString() || "";
      setStore(storeIDs);
      setStationId(response?.result?.defaultProductStationId);
      setSaleChannelConfigIds(scci);
      setSalePersonIds(spIds);
    }

    setLoading(false);
  };
  useEffect(() => {
    handleGetAllsalePersonConfig();
  }, []);
  return {
    loading,
    saleChannelConfigIds,
    store,
    salePersonIds,
    stationId,
  };
};
